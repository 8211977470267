/* ProductDetails.css */
.product-details-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .product-title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #007BFF;
    text-transform: capitalize;
  }
  
  .product-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
    text-transform: capitalize;
  }
  
  .product-table h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .product-table ul, 
  .product-table ol {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .product-table li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .product-details-container {
      padding: 10px;
    }
  
    .product-title {
      font-size: 24px;
    }
  
    .product-details {
      font-size: 16px;
    }
  
    .product-images {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .product-title {
      font-size: 22px;
    }
  
    .product-details {
      font-size: 14px;
    }
  
    .product-images {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
  