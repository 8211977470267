.contact-section {
    padding: 20px;
  }
  
  .contact-heading {
    text-align: center;
    margin-top: 30px;
  }
  
  .contact-heading h2 {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2A739C;
  }
  
  .contact-map {
    margin: 20px 0;
  }
  
  .contact-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .contact-image {
    flex: 1;
    text-align: center;
  }
  
  .contact-image img {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
  
  .contact-form {
    flex: 1;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-control:focus {
    outline: none;
    border-color: #2A739C;
  }
  
  .btn-submit {
    background-color: #2A739C;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .btn-submit:hover {
    background-color: #1f5c80;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      gap: 20px;
    }
  
    .contact-image img {
      margin-top: 20px;
    }
  
    .contact-form {
      margin-top: 0;
    }
  }
  