.productsection {
    background-image: url(../../Images/product/ourproductimage.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* height: 500px; */
    background-color: #F2EDEB;
}

.productmain {
    padding: 30px;
}

.productsmallheading {
    margin-top: 50px;
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    opacity: 0.8;
}

.productheading {
    font-size: 39px;
    opacity: 0.9;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
}

.productdiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content:start;
}

.product {
    width: 23%;
    background-color: white;
    overflow: hidden;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: auto;
}

.product .img {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-bottom: 5px solid #2A739C;
}

.product .img img {
    width: 100%;
    height:300px;
    /* object-fit: cover; */
    object-fit: contain;
    transition: transform 0.3s ease;
}

.product:hover .img img {
    transform: scale(1.2);
    /* border: 1px solid black; */
}

.detail {
    overflow: hidden;
}

.detail p {
    margin-left: 15px;
    margin-top: 10px;
    font-weight: 800;
}

.detail button {
    background-color: #2A739C;
    border: 0px;
    border-radius: 5px;
    margin-left: 15px;
    margin-bottom: 15px;
}

.detail button a {
    text-decoration: none;
    color: white;
    font-size: 17px;
    padding: 2px;
}


/* responsive */

@media(max-width:768px) {
    .product {
        width: 48%;
    }

    .productdiv {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
    }

    .productheading {
        font-size: 27px;
    }

    .productsmallheading {
        margin-top: 1px;
        font-size: 13px;
    }
    .productmain {
        padding: 6px;
    }
}