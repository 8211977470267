.client-section {
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .client-section h2 {
    font-size: 3em;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .client-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
  }
  
  .client-item {
    flex: 0 1 calc(20% - 40px); /* 4 images per row with margin */
    margin: 10px;
    box-sizing: border-box;
    padding: 10px; /* Adding padding around each client item */
  }
  
  .client-item img {
    max-width: 100%;
    height: 180px; /* Fixed height for images */
    object-fit: contain; /* Maintain aspect ratio */
    display: block;
    border: 2px solid #ddd;
    padding: 10px;
    background-color: #fff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .client-item img:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .client-item {
      flex: 0 1 calc(50% - 40px); /* 2 images per row on medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .client-grid {
      padding: 0; /* Remove padding on mobile screens */
    }
  
    .client-item {
      flex: 0 1 50%; /* 2 images per row on small screens */
      margin: 5px 0; /* Remove horizontal margin on mobile screens */
      padding: 5px; /* Reduce padding on mobile screens */
    }
  
    .client-section h2 {
      font-size: 1.5em;
    }
  }
  