/* General Styles */
section {
    width: 100%;
  }
  
  /* Desktop styles */
  .desktop {
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #2E85C4, #EB1C22);
    padding: 0 40px;
  }
  
  .navlogo {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .navlogo img {
    height: 150px;
    width: auto;
  }
  
  .navlinks {
    display: flex;
    align-items: center;
  }
  
  .navlinks ul {
    display: flex;
    gap: 30px;
    list-style-type: none;
  }
  
  .navlinks ul li a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
  }
  
  /* Mobile styles */
  .mobile {
    display: none;
    flex-direction: column;
    background-color: #2A739C;
  }
  
  .navlogomobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 62px;
  }
  
  .navlogomobile img {
    height: 50px;
    width: auto;
  }
  
  .navmenu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navmenu i {
    font-size: 23px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
  
  .navlinksmobile {
    display: flex;
    flex-direction: column;
    background-color: #2A739C;
  }
  
  .navlinksmobile ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .navlinksmobile ul li {
    padding: 10px 20px;
  }
  
  .navlinksmobile ul li a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
  