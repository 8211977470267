.testimonialheading {
    text-align: center;
    font-size: 30px;
    font-family: 'Cursive', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 30px;
    color: #333;
    position: relative;
}

.testimonialheading::after {
    content: '';
    width: 50px;
    height: 4px;
    background: #ff6f61;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}

.testimonialContainer {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

.testimonialContent {
    width: calc(33.33% - 20px);
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.testimonialContent:hover {
    transform: scale(1.05);
}

.imagetestimonialdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.clientPhoto {
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.testimonialText {
    text-align: center;
}

.testimonial {
    font-size: 15px;
    font-weight: 600;
    opacity: 0.9;
    margin-bottom: 10px;
    color: #333;
    text-align: justify;
}

.stars {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.star {
    color: #ff6f61;
    margin: 0 2px;
    font-size: 20px;
}

.clientName {
    font-weight: bold;
    margin: 5px 0 2px;
    color: #ff6f61;
}

.clientPosition {
    color: #555;
    font-size: 0.9em;
    margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
    .testimonialContent {
        width: calc(50% - 20px);
        margin-bottom: 20px;
    }
}

@media (max-width: 600px) {
    .testimonialContainer {
        flex-direction: column;
        gap: 20px;
    }
    .testimonialContent {
        width: 100%;
    }
    .testimonialText {
        max-width: 100%;
        text-align: center;
    }
    .clientPhoto {
        margin: 0 auto 15px;
    }
}
